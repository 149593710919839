<template lang="html">
  <div id="menu-detail-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-lg-6 col-xl-5 col-info">
          <div class="box-breadcrumb" data-aos="fade-up">
            <span><router-link to="/menu">MENÚ</router-link></span>
            
            <span><router-link :to="'/menu?category='+row.category_id">{{row.category}}</router-link></span>
            <span class="current">{{row.name}}</span>
          </div>

          <div class="box-info" data-aos="fade-up" data-aos-delay="300">
            <h1 class="title">{{row.name}}</h1>

            <div class="descr">{{row.description}}</div>
          </div>
        </div>

        <div class="col-lg-6 offset-xl-1 col-image" data-aos="fade-up" data-aos-delay="500">
          <div class="box-carousel">
            <swiper class="swiper swiper-s1" :options="galleryOptions">
              <swiper-slide v-for="(g, gInx) in row.gallery" :key="'gInx-'+gInx">
                <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+ g +')' }"></div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      galleryOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        }
      },
      row:{
        gallery:[],
      }
    }
  },
  watch:{
      'currentCat':function(){
          this.getProduct();
      }
  },  
  methods: {
      getProduct: function(){
          axios.get(tools.url('/api/product/'+this.id)).then((response)=>{
              this.row = response.data;
          });
      }
  },
  mounted() {
      this.id = this.$route.params.id;
      this.getProduct();
  }
}
</script>
