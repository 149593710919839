<template lang="html">
  <div id="home-page">

    <section class="banner-section" data-aos="fade">
      <swiper class="swiper swiper-s1 desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners.desktop" :key="'bhInx-'+bhInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">

            <div class="box-content">
              <div class="container oversized-container">
                <h3 class="txt-1">Tradición Europea</h3>

                <!-- <p>
                  <router-link class="t-250 _btn" to="/menu">Explora nuestro menú</router-link>
                </p> -->

                <div class="box-extra-content">
                  Hoy y siempre<br />comida sana
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper swiper-s1 mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners.mobile" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">

            <div class="box-content">
              <div class="container oversized-container">
                <h3 class="txt-1">Tradición Europea</h3>

                <!-- <p>
                  <a class="t-250 _btn" href="#">Explora nuestro menú</a>
                </p> -->

                <div class="box-extra-content">
                  Hoy y siempre<br />comida sana
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <div class="placed-backg bg-gray">
      <!-- <section class="branch-offices-section">
        <div class="container oversized-container">
          <div class="row">
            <div class="col-12 col-title">
              <h2 class="title-s1" data-aos="fade-up">Sucursales</h2>
            </div>

            <div class="col-12 col-carousel" data-aos="fade-up" data-aos-delay="300">
              <swiper class="swiper" :options="officesOptions">
                <swiper-slide v-for="(item, ihInx) in offices" :key="'ihInx-'+ihInx">
                  <div class="placed-backg box-branch-office">
                    <img :src="item.imageUrl">
                    <router-link class="fake-link" :to="'/sucursales?s='+item.id"></router-link>

                    <div class="box-text">
                      <h5 class="name">{{ item.name }}
                        <a class="btn-whatsapp" :href="'tel:'+item.phone">
                        </a>
                      </h5>
                    </div>
                  </div>
                </swiper-slide>

                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </div>
      </section> -->

      <section class="dishes-section">
        <div class="container oversized-container">
          <div class="row">
            <div class="col-12 col-title" data-aos="fade-up">
              <h2 class="title-s1">Nuestro Concepto</h2>
            </div>

            <div class="col-12 col-text" data-aos="fade-up" data-aos-delay="100">
              <p>
                Todas las recetas de Boca 21 Deli son elaboradas con el corazón e insumos de alta calidad para que a un verdadero degustador le sea difícil permanecer indiferente ante ellas. Guadalajara permite entremezclar tradiciones provenientes de países como España, Francia y otros. Los sabores se encuentran a través de una preparación de modo natural, sin aditivos químicos y con un afán casero. A continuación un paseo virtual que no debe sustituir al vivido. Te invitamos a ser parte de esta tradición.
              </p>
            </div>

            <div class="col-12 mt-4 pt-sm-2 col-carousel" data-aos="fade-up" data-aos-delay="200">
              <swiper class="swiper swiper-s1" :options="galleryOptions">
                <swiper-slide v-for="(f, fhInx) in blogs" :key="'fhInx-'+fhInx">
                  <a class="box-gallery-s1">
                    <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+f.imageUrl+')' }">
                      <img src="public/images/shared/empty.png" alt="">
                    </div>
                  </a>
                </swiper-slide>

                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div>
          </div>
        </div>

        <CoolLightBox
          :items="GalleryItems"
          :index="index"
          :effect="'fade'"
          loop
          @close="index = null">
        </CoolLightBox>
      </section>
    </div>

    <!-- <section class="placed-backg bricks-section"></section> -->

  </div>

</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },

  data() {
    return {
      banners: {
        desktop: [
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
          { imageUrl: 'public/images/pages/home/banner-2.jpg' },
          { imageUrl: 'public/images/pages/home/banner-3.jpg' },
          { imageUrl: 'public/images/pages/home/banner-4.jpg' },
          { imageUrl: 'public/images/pages/home/banner-5.jpg' },
          { imageUrl: 'public/images/pages/home/banner-6.jpg' },
          { imageUrl: 'public/images/pages/home/banner-7.jpg' },
        ],

        mobile: [
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-2-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-3-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-4-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-5-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-6-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-7-m.jpg' },
        ],
      },

      offices: [
        { id: 4, imageUrl: 'public/images/pages/branch-offices/bo-1.jpg', name: 'Plaza del Sol', phone: '3331212842' },
        { id: 2, imageUrl: 'public/images/pages/branch-offices/bo-2.jpg', name: 'Chapalita', phone: '3336477035' },
        { id: 3, imageUrl: 'public/images/pages/branch-offices/bo-3.jpg', name: 'Gran Plaza', phone: '3331221533' },
        { id: 1, imageUrl: 'public/images/pages/branch-offices/bo-4.jpg', name: 'Providencia', phone: '3336410151' },
      ],

      blogs: [

      ],

      GalleryItems: [],
      index: null,

      // == Carousel options ==
      bannersOptions: {
        effect: 'fade',
        loop: true,
        speed: 700,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },

      officesOptions: {
        slidesPerView: 4,
        spaceBetween: 30,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1500: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        }
      },

      galleryOptions: {
        slidesPerView: 4,
        spaceBetween: 30,
        loop: true,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          1400: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          1: {
            slidesPerView: 1,
          },
        },
      },
      // == ==
    }
  },

  methods: {
    setIndex() {
      this.index = 0
    },

    showGallery(idx) {
      this.GalleryItems = this.blogs[idx].images;

      setTimeout(() => {
        this.setIndex();
      }, 200);
    },
    getBlogs: function(){
          axios.get(tools.url('/api/blogs')).then((response)=>{
              this.blogs = response.data;
          });
      }
  },
  mounted(){
       this.getBlogs();
  }
}
</script>
