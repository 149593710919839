<template lang="html">
  <div id="facturacion-page">
    <!-- <div class="img-1" v-rellax="{ speed: -3 }"></div> -->

    <section class="container main-section">
      <div class="box-title" data-aos="fade-up">
        <h1 class="title-s1">Facturación</h1>
      </div>

      <div class="box-form" data-aos="fade-up" data-aos-delay="300">
        <b-form class="row" @submit="onSubmit">
          <div class="col-lg-6 mx-auto">
            <div class="row">
              <div class="col-12">
                <b-form-group
                  label="Nombre completo"
                  class="custom-f-group-s1">
                  <b-form-input
                    v-model="form.name"
                    type="text"
                    size="lg"
                    placeholder="Nombre completo"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-12">
                <b-form-group
                  label="Correo electrónico"
                  class="custom-f-group-s1">
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    size="lg"
                    placeholder="Escribe tu correo electrónico"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-12">
                <b-form-group
                  label="Numero de ticket"
                  class="custom-f-group-s1">
                  <b-form-input
                    v-model="form.ticket"
                    type="text"
                    size="lg"
                    placeholder="Escribe tu numero de ticket"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-12">
                <b-form-group
                  label="Fecha del ticket"
                  class="custom-f-group-s1">
                  <b-form-input
                    v-model="form.date"
                    type="date"
                    size="lg"
                    placeholder=""
                    minlength="10"
                    maxlength="10"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-12">
                <b-form-group
                  label="Cantidad"
                  class="custom-f-group-s1 group-money">
                  <b-form-input
                    v-model="form.amount"
                    type="number"
                    size="lg"
                    placeholder="Escribe la cantidad"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-12">
                <b-form-group
                  label="Comentarios"
                  class="custom-f-group-s1">
                  <b-form-textarea
                    v-model="form.msg"
                    placeholder="¿Tienes algun comentario?"
                    rows="2"
                    max-rows="2"
                    required
                  ></b-form-textarea>
                </b-form-group>
              </div>

              <div class="col-12 mt-3 mt-lg-4 text-center">
                <b-button type="submit" class="btn-s1">FACTURAR</b-button>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: null,
        email: null,
        ticket: null,
        date: null,
        amount: null,
        msg: null,
      }
    }
  },

  methods: {
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-bottom-right',
        appendToast: true
      })
    },

    onSubmit(event) {
      event.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/facturacion");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Mensaje enviado');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    },
  }
}
</script>
