<template lang="html">
  <div id="menu-page">
    <!-- <div class="img-1" v-rellax="{ speed: -3 }"></div> -->

    <section class="container oversized-container main-section">
      <div class="box-title">
        <h1 class="title-s1" data-aos="fade-up">Menú</h1>
      </div>

      <div class="box-categories" data-aos="fade-up" data-aos-delay="300">
        <a class="btn-category"
          v-bind:class="{ active : currentCat == c.id }"
          v-for="(c, cmInx) in categories"
          :key="'cmInx-'+cmInx"
          @click="currentCat = c.id">
          {{ c.name }}
        </a>

        <div class="box-category-info">
          <div class="box">
            <p>{{current_description}}</p>
          </div>
        </div>
      </div>

      <!-- Bocadillos -->
      <div class="row box-foods" data-aos="fade-up" data-aos-delay="500" >
        <div class="col-lg-6 col-food" v-for="(f, fhInx) in products" :key="'fhInx-'+fhInx">
          <router-link class="box-dish-s1" :to="''">
            <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+f.imageUrl+')' }"></div>

            <div class="col col-text">
              <h4 class="name">{{ f.name }}</h4>

              <div class="descr">{{ f.short_description }}</div>
              <h4 class="name">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(f.price) }}</h4>
            </div>
          </router-link>
        </div>
      </div>

    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      currentCat: null,
      categories: [],
      products: [],
      current_description:null,
    }
  },

  watch:{
    'currentCat':function(){
      this.$router.push({ path: '/menu', query: { category: this.currentCat } });
      var current_description = null;
      for (let x = 0; x < this.categories.length; x++) {
        if(this.categories[x]['id'] == this.currentCat){
            current_description = this.categories[x]['description'];
            break;
        }
        
      }
      this.current_description = current_description;
      this.getMenu();
    }
  },

  methods: {
    getCategories: function(){
      axios.get(tools.url('/api/categories')).then((response)=>{
        this.categories = response.data;
        if(this.$route.query.category){
          this.currentCat = this.$route.query.category;
        }
        else{
          this.currentCat = this.categories[0]['id'];
        }
      });
    },
    getMenu: function(){
      
      axios.get(tools.url('/api/products/'+this.currentCat)).then((response)=>{
        this.products = response.data;
      });
    }
  },

  mounted() {
    this.getCategories();
    this.getMenu();
  }
}
</script>
