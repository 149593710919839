require('./bootstrap');

import Vue from 'vue';
//Rutas del website
import Router from './router.js';

//Librerias globales
import Library from './libs.js';
Vue.use(Library);

//Componentes del website
import components from './components/components.js';
Vue.use(components);

// AOS https://egghead.io/blog/how-to-use-the-animate-on-scroll-aos-library-in-vue
import AOS from 'aos';
import 'aos/dist/aos.css';

window.Vue=Vue;

//Instancia principal
const app = new Vue({
  el: '#app',
  router:Router,

  mounted:function(){
    AOS.init();
  },
});
