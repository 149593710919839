<template lang="html">
  <header id="header">
    <div class="header-content">
      <!-- <section class="top-section">
        <div class="container oversized-container">
          <router-link class="t-150 btn-focus" to="">ORDENA AQUÍ</router-link>
          <router-link class="t-250 btn-circle" to="/contacto"><i class="fal fa-envelope"></i></router-link>
        </div>
      </section> -->

      <section class="menu-section">
        <b-navbar toggleable="lg" type="dark" variant="">
          <div class="container oversized-container">
            <b-navbar-brand to="/">
              <div class="img"></div>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse">
              <i class="far fa-bars"></i>
            </b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="ml-auto">
                <b-nav-item to="/sucursales">SUCURSALES</b-nav-item>
                <b-nav-item to="/menu">MENÚ</b-nav-item>
                <b-nav-item to="/empresa">EMPRESA</b-nav-item>
                <li class="nav-item">
                  <a class="nav-link" target="_blank" href="https://boca21deli.yomefacturo.mx/v2/">FACTURACIÓN</a>
                </li>
                <!-- <b-nav-item to="/facturacion">FACTURACIÓN</b-nav-item> -->
                <b-nav-item to="/contacto">CONTACTO</b-nav-item>
                <!-- <b-nav-item to="">ORDENA AQUÍ</b-nav-item> -->
                <!-- <b-nav-item to="/especiales">ESPECIALES</b-nav-item> -->
                <!-- <b-nav-item class="nav-focus" to="">ORDENA AQUÍ</b-nav-item> -->
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>
      </section>
    </div>
  </header>
</template>

<script>
export default {
}
</script>
