<template lang="html">
  <div id="branch-offices-page">
    <!-- <div class="img-1" v-rellax="{ speed: -3 }"></div> -->

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-lg-5 col-info">
          <div class="box-title" data-aos="fade-up">
            <h1 class="title-s1">Sucursales</h1>
          </div>

          <div class="box-offices" data-aos="fade-up" data-aos-delay="300">
            <div class="box-office"
              v-bind:class="{ active : mapCenter.id == m.id }"
              v-for="(m, mInx) in markers"
              :key="'mInx-'+mInx"
              @click="goMarker(m)">
              <h5 class="t-150 name"><i class="fal fa-map-marker-alt icon"></i> {{ m.name }}</h5>
              <div class="t-150 address" v-html="m.content">
              </div>
              <div class="t-150 mt-1 address">
                Tel: {{m.phone}}
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-7 col-map" data-aos="fade-in" data-aos-delay="600">
          <GmapMap
            :center="mapCenter.position"
            :zoom="mapCenter.position.zoom">
            <GmapMarker
              :key="'marker-'+mInx"
              v-for="(m, mInx) in markers"
              :position="m.position"
              :title="m.name"
              :clickable="true"
              :draggable="false"
              :icon="{ url: 'public/images/shared/map-icon.svg'}"
              @click="markAction(m)"
            />
          </GmapMap>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      mapCenter: { id: null, position: { lat: 20.6712689, lng: -103.3923762, zoom: 14.0 } }, // Centro del mapa

      markers: [
        { id: 4, name: 'Boca 21 Deli, Plaza del Sol', phone: '33 3121 2842', position: { lat: 20.6513932, lng: -103.4020983, zoom: 17.4 }, content: `Av. Adolfo López Mateos Sur 2375,<br />Cd del Sol, 45055 Zapopan, Jal.` },
        { id: 1, name: 'Boca 21 Deli, Providencia', phone: '33 3641 0151', position: { lat: 20.685006, lng: -103.3850787, zoom: 17.4 }, content: `Av. Manuel Acuña 2732, Lomas de Guevara,<br />44679 Guadalajara, Jal.` },
        { id: 3, name: 'Boca 21 Deli, Gran Plaza', phone: '33 3122 1533', position: { lat: 20.6730545, lng: -103.4047245, zoom: 17.4 }, content: `Avenida Vallarta Eje Poniente 3959, Interior 22,<br />La Gran Plaza, 45049 Zapopan, Jal.` },
        // { id: 2, name: 'Boca 21 Deli, Chapalita', phone: '33 3647 7035', position: { lat: 20.6653555, lng: -103.4064315, zoom: 17.4 }, content: `Av Guadalupe 1579, Chapalita Oriente,<br />45040 Zapopan, Jal.` },
      ],
    }
  },

  methods: {
    goMarker(marker) {
      this.mapCenter = marker;
    }
  },

  beforeMount() {
    let idBranch = this.$route.query.s;

    if(idBranch) {
      for (var i = 0; i < this.markers.length; i++) {
        if(idBranch == this.markers[i].id) {
          this.mapCenter = this.markers[i];
          break;
        }
      }
    }
  },
}
</script>
