<template lang="html">
  <div id="about-us-page">
    <!-- <div class="img-1" v-rellax="{ speed: -3 }"></div> -->

    <section class="container oversized-container main-section">
      <div class="box-title" data-aos="fade-up">
        <h1 class="title-s1">Nuestra Empresa</h1>
      </div>

      <div class="row box-info box-info-1">
        <div class="col-lg-6 col-text" data-aos="fade-up" data-aos-delay="300">
          <h2 class="mb-2 mb-lg-4 pb-xl-2 subtitle">Quienes Somos</h2>

          <p>
            En 2002 nace la primera barra de bocadillos en Plaza del Sol, en la Ciudad de Guadalajara. La idea nace por del deseo de ofrecer al consumidor una comida sana, rápida, económica y distintiva.
          </p>
          <p class="mt-4">
            Boca21Deli amplió sus instalaciones para seguir con el mismo concepto casual, pero en un ambiente más cosmopolita, como los que hay en Italia, Francia, España o Nueva York, en un ambiente relajado y cálido.
          </p>
        </div>

        <div class="col-lg-6 col-image" data-aos="fade-up" data-aos-delay="500">
          <img src="public/images/pages/about-us/image-1.jpg">
        </div>

        <div class="col-lg-9 col-xl-7 text-center mx-auto col-text c-t2" data-aos="fade-up" data-aos-delay="300">
          <p>
            Ante la demanda del consumidor, Boca21Deli se amplió e incursionó en las líneas de desayunos, ofreciendo omelettes, quichés y otros, así como pastas en diferentes salsas, e innovando el concepto de woks, que consiste en escoger combinaciones de vegetales, carnes y salsas que son preparadas ante el cliente y acompañados de arroz blanco al vapor.
          </p>
        </div>
      </div>

      <div class="row box-info box-info-2">
        <div class="col-lg-4 col-image-2" data-aos="fade-right" data-aos-offset="500">
          <img src="public/images/pages/about-us/meat.png">
        </div>

        <div class="col-lg-8 col-text" data-aos="fade-left" data-aos-offset="500">
          <p>
            En 2004, abre su sucursal en Gran Plaza y en 2010 es creado el Corporativo con oficinas, centro de distribución y Cocina Central, de donde oportunamente son abastecidas  las sucursales. En ese mismo año se inaugura la sucursal Providencia y en 2011 se abre la sucursal de Chapalita.<br />
            <br />
            Boca21Deli considera sus alimentos como "Comida Sana" por estar basados en la dieta mediterránea compuesa por aceites de oliva, vegetales, verduras, legumbres, pan y cereales, que acompañados de una copa de vino han resultado ser beneficiosos para la salud.
          </p>
        </div>
      </div>

      <div class="row box-info mt-lg-4 box-info-1" data-aos="fade-up" data-aos-offset="400">
        <div class="col-lg-6 col-text">
          <p>
            Boca21Deli se esmera en capacitar a su personal, en satisfacer a sus clientes y en desarrollar la cultura y el gusto por el buen comer, a un precio accesible y respetando el preciado tiempo del comensal.
          </p>
        </div>

        <div class="col-lg-6 col-xl-5 offset-xl-1 col-image">
          <img src="public/images/pages/about-us/image-2.jpg">
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
