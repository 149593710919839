<template lang="html">
  <footer class="placed-backg" id="footer">
  	<div class="container" data-aos="fade-in">
  		<div class="row">
				<div class="col-lg-9 offset-lg-1">
					<div class="row">
						<!-- <div class="col-lg-4 col-footer">
							<p class="mb-4">
								<router-link class="f-w-600 link-menu" to="/menu">MENU</router-link>
							</p>
							<p class="mb-4">
								<router-link class="f-w-600 link-menu" to="/sucursales">SUCURSALES</router-link>
							</p>
							<p class="mb-4">
								<router-link class="f-w-600 link-menu" to="/especiales">ESPECIALES</router-link>
							</p>
							<p class="mb-4 mb-lg-0">
								<router-link class="f-w-600 link-menu" to="/empresa">EMPRESA</router-link>
							</p>
						</div>

						<div class="col-lg-8 col-footer">
							<p class="mb-4">
								<router-link class="f-w-600 link-menu" to="/contacto">CONTACTO</router-link>
							</p>

							<p class="mb-4">
								<a class="text-w-icon" href="mailto:contacto@boca21deli.com"><i class="fal fa-envelope"></i> contacto@boca21deli.com</a>
							</p>
							<p class="mb-4">
								<a class="text-w-icon" href="tel:3338102011"><i class="fal fa-phone-alt"></i> 33-3810-2011</a>
							</p>

							<div class="box-networks">
								<a class="t-150 btn-network" target="_blank" href="https://www.facebook.com/boca21deli?mibextid=9R9pXO">
									<i class="fab fa-facebook-f"></i>
								</a>
								<a class="t-150 btn-network" target="_blank" href="https://instagram.com/boca21delioficial?igshid=M2RkZGJiMzhjOQ==">
									<i class="fab fa-instagram"></i>
								</a>
								<a class="t-150 btn-network" target="_blank" href="https://wa.me/3335787493">
									<i class="fab fa-whatsapp"></i>
								</a>
							</div>
						</div> -->

            <div class="col-12 col-lg-10 col-footer">
              <div class="justify-content-center box-networks">
								<a class="t-150 btn-network" target="_blank" href="https://www.facebook.com/boca21deli?mibextid=9R9pXO">
									<i class="fab fa-facebook-f"></i>
								</a>
								<a class="t-150 btn-network" target="_blank" href="https://instagram.com/boca21delioficial?igshid=M2RkZGJiMzhjOQ==">
									<i class="fab fa-instagram"></i>
								</a>
								<!-- <a class="t-150 btn-network" target="_blank" href="https://wa.me/3335787493">
									<i class="fab fa-whatsapp"></i>
								</a> -->
							</div>
            </div>

						<div class="col-12 col-lg-10 col-copy">
							<h6>Todos los derechos reservados. © {{ currentYear }} Boca 21 Deli</h6>

              <p class="mt-2">
                <router-link to="/aviso-de-privacidad"><u>Aviso de privacidad</u></router-link>
              </p>
						</div>
					</div>
				</div>
  		</div>
  	</div>
  </footer>
</template>

<script>
export default {
	data() {
		return {
			currentYear: 0,
		}
	},

	beforeMount() {
		this.currentYear = new Date().getFullYear();
	}
}
</script>
