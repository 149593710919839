<template lang="html">
  <div id="menu-page">
    <!-- <div class="img-1" v-rellax="{ speed: -3 }"></div> -->

    <div class="container oversized-container main-section">
      <div class="box-title" data-aos="fade-up">
        <h1 class="title-s1">Especiales</h1>
      </div>

      <div class="row box-foods" data-aos="fade-up" data-aos-delay="300">
        <div class="col-lg-6 col-food" v-for="(f, fhInx) in food" :key="'fhInx-'+fhInx">
          <router-link class="box-dish-s1" to="/menu/1">
            <div class="col placed-backg col-image" v-bind:style="{ backgroundImage: 'url('+f.imageUrl+')' }"></div>

            <div class="col col-text">
              <h4 class="name">{{ f.name }}</h4>

              <div class="descr">
                Paella marinera con mariscos.<br />
                Receta típica de cocina española
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      food: [
        { imageUrl: 'public/images/pages/food/food-5.jpg', name: 'Ibiza' },
        { imageUrl: 'public/images/pages/food/food-4.jpg', name: 'Ibérica' },
        { imageUrl: 'public/images/pages/food/food-3.jpg', name: 'Francesa' },
        { imageUrl: 'public/images/pages/food/food-2.jpg', name: 'Bilbaína' },
        { imageUrl: 'public/images/pages/food/food-1.jpg', name: 'Alemana' },
        { imageUrl: 'public/images/pages/food/food-5.jpg', name: 'Noruega' },
        { imageUrl: 'public/images/pages/food/food-4.jpg', name: 'Inglesa' },
        { imageUrl: 'public/images/pages/food/food-2.jpg', name: 'Alemana' },
        { imageUrl: 'public/images/pages/food/food-1.jpg', name: 'Romana' },
        { imageUrl: 'public/images/pages/food/food-3.jpg', name: 'Bilbaína' },
      ],
    }
  }
}
</script>
